import React from "react";
import styled from "styled-components";
import Box from "ui-box";
import { Typography } from "@material-ui/core";
import { Display } from "./utility/Display";
import ReactPlayer from "react-player";

export const About = styled(({ className, ...props }) => {
  return (
    <Box className={className} {...props}>
      <Display>
        <Typography gutterBottom variant="body1">
          Anthony Tešija (he/him) is a Croatian-American Senior Software Engineer and Game Developer in San Francisco CA
        </Typography>

        <Box className="description">
          <Typography variant="h4">Game Developer</Typography>
          <Typography gutterBottom variant="body1">
            Designer, developer, and artist on fourty plus independent games.
            Creator and maintainer of libraries focused on making game
            development easier and more accessible which are used by other game
            makers around the world. My games tend to be bright and colorful
            with interesting twists on local multiplayer and exploration. I make
            games to bring people together.
          </Typography>
          <Box className="previews">
            <img
              className="previewImage"
              src={process.env.PUBLIC_URL + "/images/games/gourmelee.gif"}
              alt=""
            />
            <img
              className="previewImage"
              src={
                process.env.PUBLIC_URL +
                "/images/games/glitchslap2.gif"
              }
              alt=""
            />
            <img
              className="previewImage"
              src={process.env.PUBLIC_URL + "/images/games/dreamtides.gif"}
              alt=""
            />
          </Box>
        </Box>

        <Typography variant="h4">Software Engineer</Typography>
        <Typography gutterBottom variant="body1">
          Senior software engineer with over ten years experience as a developer, technical lead, and engineering manager.
        </Typography>
        <Box className="work">
          <img
            className="workplaceIcon"
            src={process.env.PUBLIC_URL + "/images/discord.png"}
            alt=""
          />
          <Box>
            <Typography gutterBottom variant="h6">
              Discord
            </Typography>
              <Typography gutterBottom variant="body1">
              Developer relations for web games and Discord activities
            </Typography>
          </Box>
        </Box>
        <Box className="work">
          <img
            className="workplaceIcon"
            src={process.env.PUBLIC_URL + "/images/buck.png"}
            alt=""
          />
          <Box>
            <Typography gutterBottom variant="h6">
              Buck
            </Typography>
              <Typography gutterBottom variant="body1">
              VFX for The Electric State: Kid Cosmo
            </Typography>
          </Box>
        </Box>
        <Box className="work">
          <img
            className="workplaceIcon"
            src={process.env.PUBLIC_URL + "/images/echochess.png"}
            alt=""
          />
          <Box>
            <Typography gutterBottom variant="h6">
              Echo Chess
            </Typography>
            {/* <Typography gutterBottom variant="body1">
              Design, development, and prototyping for the daily puzzle game Echo Chess where you are what you eat. Built a fully featured level editor that allowed players to create, save, and share their own puzzles.
            </Typography> */}
              <Typography gutterBottom variant="body1">
              Development and design for the daily puzzle game Echo Chess where you are what you eat.
            </Typography>
          </Box>
        </Box>
        <Box className="work">
          <img
            className="workplaceIcon"
            src={process.env.PUBLIC_URL + "/images/handstand.png"}
            alt=""
          />
          <Box>
            <Typography gutterBottom variant="h6">
              Handstand
            </Typography>
              <Typography gutterBottom variant="body1">
              Developed software for a fully customizable, installation-based escape room in Unity that worked with custom hardware via COM serial ports and allowed for remote access and updates.
            </Typography>
          </Box>
        </Box>
        <Box className="work">
          <img
            className="workplaceIcon"
            src={process.env.PUBLIC_URL + "/images/google.png"}
            alt=""
          />
          <Box>
            <Typography gutterBottom variant="h6">
              Google
            </Typography>
            <Typography gutterBottom variant="body1">
              Enhancing catalog and search for the ecommerce platform
              store.google.com.
            </Typography>
          </Box>
        </Box>
        <Box className="work">
        <img
            className="workplaceIcon"
            src={process.env.PUBLIC_URL + "/images/omm.png"}
            alt=""
          />
          <Box>
            <Typography gutterBottom variant="h6">
              One More Multiverse
            </Typography>
            <Typography gutterBottom variant="body1">
              Full stack senior engineer in charge of game feel and the combat
              system.
            </Typography>
          </Box>
        </Box>
        <Box className="work">
        <img
            className="workplaceIcon"
            src={process.env.PUBLIC_URL + "/images/apartmentlist.png"}
            alt=""
          />
          <Box>
        <Typography gutterBottom variant="h6">
          Apartment List
        </Typography>
        <Typography gutterBottom variant="body1">
          Senior engineer over a suite of Ruby on Rails micro services that
          included billing, leases, and the flow of all properties available on
          the site. Helped build a service using machine learning to detect
          watermarks on all incoming images. Led a small team to expose rent
          specials in a more meaningful way to differentiate the platform from
          competitors.
        </Typography>
          </Box>
        </Box>
        <Box className="work">
        <img
            className="workplaceIcon"
            src={process.env.PUBLIC_URL + "/images/snagit.png"}
            alt=""
          />
          <Box>
        <Typography gutterBottom variant="h6">
          Snagit - TechSmith
        </Typography>
        <Typography gutterBottom variant="body1">
          Five years experience as a developer, technical lead,
          and engineering manager for the award winning screen capture tool
          Snagit focused on computer vision and drawing tools.
        </Typography>
          </Box>
        </Box>

        <Box className="description">
          <Typography variant="h4">Live Visuals</Typography>
          <Typography gutterBottom variant="body1">
            Using Unity and custom controllers I've built my own VJ software that I use to
            create music videos, TikToks, and custom live visuals for bands and
            DJs. I've played the iconic SF venues The Independent, Rickshaw Stop, Gray Area, and Bottom of the Hill with the bands OVRGRWN and Your Fearless Leader. I'm the resident VJ for the underground venue The Spacement and I organized and ran visuals for Fanime Con in San Jose with an average of 34,000 attendees.
          </Typography>
          <Box className="previews">
            <ReactPlayer
              url="https://youtu.be/km_Y4_-4v8Y?si=P9L_cb9SWXCYYyg1"
              controls={true}
              width="260px"
              height="180px"
            />
            <ReactPlayer
              url="https://youtu.be/-XDE8MIVmRw"
              controls={true}
              width="260px"
              height="180px"
            />
          </Box>
        </Box>

        <Box className="description">
          <Typography variant="h4">Mixed Media Art</Typography>
          <Typography gutterBottom variant="body1">
            Designer and creator whose mediums include ink, pencil, acrylic
            paint, cardboard, cloth, thread, wood, rope, and digital art.
            Collaborated with musicians to create show posters, stickers, album
            art, and music visualizers for their work. Designed and built
            shelves, plant hangers, and pots. Designed, created, and modified
            custom clothing and masks.
          </Typography>
          <Box className="previews">
            <img
              className="previewImage"
              src={
                process.env.PUBLIC_URL +
                "/images/art/nikjerstickers/sticker2.jpg"
              }
              alt=""
            />
            <img
              className="previewImage"
              src={process.env.PUBLIC_URL + "/images/art/shelves/finished.jpg"}
              alt=""
            />
            <img
              className="previewImage"
              src={process.env.PUBLIC_URL + "/images/art/frogmask/frogmask.jpg"}
              alt=""
            />
          </Box>
        </Box>

        <Box className="description">
          <Typography variant="h4">Chef</Typography>
          <Typography gutterBottom variant="body1">
            Chef focused on catering high quality meals for small parties with
            local, sustainable, in-season ingredients. My experimentally
            flavored cookies have been sold in coffee shops around San Francisco
            and I sell the frozen dough directly to customers.
          </Typography>
          <Box className="previews">
            <img
              className="previewImage"
              src={process.env.PUBLIC_URL + "/images/food/foragedsteak.jpg"}
              alt=""
            />
            <img
              className="previewImage"
              src={
                process.env.PUBLIC_URL +
                "/images/food/cookies/cookiedarkchocolateespresso.jpg"
              }
              alt=""
            />
            <img
              className="previewImage"
              src={process.env.PUBLIC_URL + "/images/food/ramenpho.jpg"}
              alt=""
            />
          </Box>
        </Box>
      </Display>
    </Box>
  );
})`
  .description {
    margin-bottom: 32px;
  }

  .previews {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .work {
    display: flex;
  }

  .previewImage {
    width: 30%;
    height: 100%;
  }

  .workplaceIcon {
    width: 32px;
    height: 100%;
  }
`;
